import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@pages/home/home.component';
import { AppPreloader } from './app.preload';
import { CategoriesGuard } from './categories.guard';

const routes: Routes = [
  {
    path: '', canActivate: [CategoriesGuard], children: [
      // {
      //   path: 'daily-offer',
      //   loadChildren: () => import('@pages/daily-offers/daily-offers.module').then(m => m.DailyOffersModule),
      //   pathMatch: 'prefix',
      // },
      {
        path: 'clients',
        loadChildren: () => import('@pages/clients/clients.module').then(m => m.ClientsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'delivery',
        loadChildren: () => import('@pages/delivery/delivery.module').then(m => m.DailyOffersModule),
        pathMatch: 'prefix'
      },
      {
        path: 'orders',
        loadChildren: () => import('@pages/order/order.module').then(m => m.OrderModule),
        pathMatch: 'prefix'
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('@pages/privacy-policy/privacy.module').then(m => m.PrivacyModule),
        pathMatch: 'prefix'
      },
      {
        path: ':categoryName',
        loadChildren: () => import('@pages/categories/categories.module').then(m => m.CategoriesModule),
      },
      {
        path: ':categoryName/:productName',
        loadChildren: () => import('@pages/product-details/product-details.module').then(m => m.ProductDetailsModule),
      },
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        canActivate: [CategoriesGuard]
      },
      {
        path: '**', redirectTo: '/'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    preloadingStrategy: AppPreloader,
  })],
  exports: [RouterModule],
  providers: [AppPreloader]
})
export class AppRoutingModule { }
