import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
@NgModule({
  declarations: [],
  imports: [
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule { }
