import { Component } from '@angular/core';
import { ContextService } from '@context';
import { RepositoryService } from '@core/services/repository.service';
import { CategoryItem } from '@models/category-product.model';
import { ScreenService } from '@root/screen.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-inactive',
  templateUrl: './cart-inactive.component.html',
  styleUrls: ['./cart-inactive.component.scss']
})
export class CartInactiveComponent {
  listCategories$: Observable<CategoryItem[]>;

  public get isTablet(): boolean {
    return this.screen.state.isMD;
  }

  constructor(private context: ContextService, private screen: ScreenService, private repositoryService: RepositoryService) {
    this.listCategories$ = this.repositoryService.categoriesList();
  }

  public closeCart(): void {
    this.context.setState({ isOpenCart: false });
  }
}


