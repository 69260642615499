<section class="bottom-banner-card">
  <picture class="picture">
    <source *ngIf="!!data?.image_mobile" media="(max-width: 600px)" [attr.srcset]="data?.image_mobile" />
    <img [src]="data?.image" width="0" height="0" alt="advertise banner" loading="lazy" />
  </picture>
  <div class="text">
    <p *ngIf="data?.title" class="mat-display-3">{{data?.title}}</p>
    <p *ngIf="data?.content" class="mat-title">{{data?.content}}</p>
    <button *ngIf="data?.link" class="contained-button mat-button" data-color="primary">Primary</button>
  </div>
</section>