import { Component } from '@angular/core';
import { ContextService } from '@context';
import { RepositoryService } from '@core/services/repository.service';
import { CategoryItem } from '@models/category-product.model';
import { ScreenService } from '@root/screen.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  public listCategories$: Observable<CategoryItem[]>;
  public isDrawerOpen: boolean = false;

  public get navigationRoutes() {
    return this.context.navigationRoutes;
  }
  public get isDesktop(): boolean {
    return this.screen.state.isLG;
  }
  public get isTablet(): boolean {
    return this.screen.state.isMD || this.screen.state.isXS;
  }
  public get isMobile(): boolean {
    return this.screen.state.isXS;
  }
  public get countCart(): number {
    return this.context.countCart();
  }
  constructor(private context: ContextService, private screen: ScreenService, private repositoryService: RepositoryService) {
    this.listCategories$ = this.repositoryService.categoriesList();
  }

  public toggleCart(): void {
    this.context.setState({ isOpenCart: !this.context.state.isOpenCart });
  }
  public toggleDrawer(event: any): void {
    if (event.target.dataset.source !== 'root' || this.context.state.isOpenCart) return
    this.isDrawerOpen = !this.isDrawerOpen;
  }
  public handleTouchSwipe(event: any): void {
    if (event.swipe !== 'right') return

    this.isDrawerOpen = false;
  }
  public getActiveCategory(): number {
    return this.context.state.activeCategory;
  }
  public setActiveCategory(id: any): void {
    this.context.setState({ activeCategory: id });
  }
}
