import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ContextService } from '@context';
import { NotificationService } from '@core/services/notification.service';
import { OrderItem } from '@models/order-item.model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable()

export class ClientOrderService {
  private currentOrder: OrderItem | null = null;
  private _loading: boolean = false;

  public set isLoading(value: boolean) {
    this._loading = value;
  }
  public get isLoading(): boolean {
    return this._loading
  }

  constructor(
    private db: AngularFirestore,
    private context: ContextService,
    private notification: NotificationService,
  ) { }

  public getStatus(order: OrderItem, type: string = 'text'): string {
    if (type === 'text') {
      if (order.status === 'pending') return 'В очакване'
      if (order.status === 'in-progress') return 'Приета'
      if (order.status === 'on-the-road') return 'На път'
      if (order.status === 'canceled') return 'Отказана'
      if (order.status === 'done') return 'Приключена'
    }
    return 'error'
  }

  public getOrderDetails$(id: string): Observable<OrderItem | undefined> {
    if (!id || this.context.state.isServer) return of(undefined)
    this.isLoading = true;
    try {
      return this.db.collection<OrderItem>('orders').doc(id)
        .valueChanges({ idField: 'id' })
        .pipe(
          tap((order) => {
            if (!order || this.context.state.isServer) return
            if (['done', 'canceled'].includes(order.status)) {
              window.localStorage.removeItem('order_id');
            }
            if (!this.currentOrder) {
              this.currentOrder = order;
            }
            if (order.status !== this.currentOrder.status) {
              this.notification.showNotification(`Статус - ${this.getStatus(order)} `);
              this.currentOrder = order;
            }
            this.isLoading = false;
          }),
        );
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      return of(undefined)
    }
  }
  public async setOrderCancel(): Promise<void> {
    if (!this.currentOrder) return this.context.openFailureSnackBar('Нещо се обърка, моля свържете се с нас.');

    try {
      this.isLoading = true;
      await this.db.collection('orders')
        .doc(this.currentOrder.id)
        .update({ status: 'canceled', canceled_time: new Date() });
      this.context.openSuccessSnackBar('Поръчката ви беше отказана.');
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.context.openFailureSnackBar('Нещо се обърка, моля свържете се с нас.');
    }
  }
}
