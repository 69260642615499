<section *ngIf="topBannerList$ | async as top_banners" class="top-banner">
  <app-top-banner [data]="top_banners"></app-top-banner>
</section>

<section class="main">
  <section class="section usp">
    <h1 class="mat-display-3">
      Поръчка на премиум суши и бърза доставка до вашия офис и дом в Плевен
    </h1>

    <section class="section usp-list">
      <app-usp-card
        *ngFor="let usp of uspList$ | async"
        [title]="usp.title"
        [content]="usp.content"
        [image]="usp.image"
      >
      </app-usp-card>
    </section>
  </section>

  <section class="section products">
    <app-divider class="divider">Топ позиции</app-divider>
    <p class="mat-body-1">
      Асортимента на Ninja Sushi включва ролове, суши, сетове и напитки за всеки
      вкус. Горещо препоръчваме да опитате Топ позициите в нашето меню.
    </p>

    <section
      *ngIf="featuredCategories$ | async as categories"
      class="categories-list"
    >
      <featured-category
        *ngFor="let category of categories"
        [category]="category"
      ></featured-category>
    </section>
  </section>

  <section class="section delivery-zone">
    <app-divider class="divider">Зона за доставки</app-divider>

    <p class="mat-body-1">
      В момента доставката се извършва в маркираната зона, но ние сме готови да
      направим изключения за големи поръчки. За подробности се консултирайте с
      мениджъра.
    </p>

    <a
      aria-label="map"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.com/maps/search/?api=1&query=ninja+sushi+pleven"
    >
      <picture class="zone">
        <source
          srcset="/assets/map/map-mobile.png"
          media="(max-width: 600px)"
          type="image/png"
        />
        <source srcset="/assets/map/map-desktop.png" type="image/png" />
        <img
          src="/assets/product/product-card-default.png"
          [width]="map.width"
          [height]="map.height"
          loading="lazy"
          alt="google map delivery zone"
        />
      </picture>
    </a>
  </section>
</section>
