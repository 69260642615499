import { Component } from '@angular/core';
import { ContextService } from '@context';
import { ClientOrderService } from '@core/services/order.service';
import { first, tap } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  template: `
            <app-shell></app-shell>
            <app-snack-bar></app-snack-bar>
            <app-modals-root></app-modals-root>
            `,
})
export class AppComponent {

  constructor(
    private context: ContextService,
    private orderService: ClientOrderService,
  ) {
    const excludedStatuses = ['done', 'canceled'];
    const orderId = this.context.state.isBrowser ? (window.localStorage.getItem('order_id') || false) : false;
    if (!orderId) return

    this.orderService.getOrderDetails$(orderId).pipe(
      first(),
      tap((order: any) => {
        if (!order) return
        if (excludedStatuses.includes(order.status)) {
          window.localStorage.removeItem('order_id');
          this.context.setState({ isOrderModalOpen: false });
          this.context.navigationRoutes.filter(l => l.id !== 9);
          return
        } else if (!this.context.state.isOrderPage) {
          this.context.setState({ isOrderModalOpen: true });
        }
        this.context.navigationRoutes.push({
          id: 9,
          title: 'Моята поръчка',
          link: `/orders/details/${order.id}`,
          name: 'my-order'
        })
      })
    ).subscribe();
  }
}
