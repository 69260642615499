import { Injectable } from '@angular/core';

type textTransform = 'lowercase' | 'uppercase' | 'capitalize';

@Injectable()

export class UtilitiesService {

  constructor() { }

  public numberFallback(value: any, fallback: any[]): number {
    const isInvalidNumber = (n: any): boolean => {
      return (isNaN(Number(n)) || (typeof n === 'undefined') || n === null || n === false)
    }
    let invalidStatus = isInvalidNumber(value);
    if (!invalidStatus) return Number(value);

    const fallbackData = fallback.slice();
    while (invalidStatus && !!fallbackData.length) {
      value = fallbackData.shift();
      invalidStatus = isInvalidNumber(value);
    }
    if (!fallbackData.length && invalidStatus) return 0
    return Number(value);
  }

  public timeTemplate(date: number): string {
    const time = new Date(date);
    time.setUTCHours(0);
    time.setUTCMinutes(0);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.toUTCString();
  }

  public formatText = (text: string, delimiter: string, join: string, type: textTransform = 'lowercase') => {
    if (!text) return '';
    let words = text.toString().trim().split(delimiter);
    if (type === 'lowercase') words = words.map(part => part.toLowerCase());
    if (type === 'capitalize') words = words.map(part => `${part.toUpperCase()[0]}${part.toLowerCase().substring(1)}`);
    if (type === 'uppercase') words = words.map(part => part.toUpperCase());
    return words.join(join);
  }

  public parseToBoolean(value: string | boolean | undefined): boolean {
    if (typeof value === 'undefined') return false
    if (typeof value === 'string') {
      if (value === 'true') return true
      return false
    }
    return value
  }

  public chunkArray = (arr: Array<any>, chunkSize: number): Array<any> => {
    if (chunkSize <= 0) throw "Invalid chunk size";
    const temp = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize) {
      temp.push(arr.slice(i, i + chunkSize));
    }
    return temp;
  }
}
