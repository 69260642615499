import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { environment } from '@env';
import { ConfigurationItem } from '@models/configuration-item.model';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class ConfigurationService {
  private readonly configurationDbRef: AngularFirestoreDocument<ConfigurationItem> = this.db.collection<ConfigurationItem>('application').doc('configurations');
  private initialState: ConfigurationItem = {
    ...environment.DEFAULT_APP_CONFIGURATION,
    ...this.transferState.get(makeStateKey<Object>('configurations'), {})
  };
  public readonly state$: BehaviorSubject<ConfigurationItem> = new BehaviorSubject<ConfigurationItem>(this.initialState);

  constructor(
    private db: AngularFirestore,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformServer(this.platformId)) {
      this.configurationDbRef
        .valueChanges()
        .pipe(take(2))
        .subscribe((response) => {
          if (!response) return
          this.setState(response);
        });
    }
    if (isPlatformBrowser(this.platformId)) {
      this.configurationDbRef
        .valueChanges()
        .subscribe((response) => {
          if (!response) return
          this.setState(response);
        });
    }
  }

  //STATE
  public get configurations(): ConfigurationItem {
    return this.state$.getValue();
  }
  public setState = (newState: Partial<any>): void => {
    const updatedState = { ...this.configurations, ...newState };
    this.transferState.set(makeStateKey<Object>('configurations'), updatedState)
    this.state$.next(updatedState);
  }
}