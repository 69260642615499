import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ContextService } from '@context';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})

export class ShellComponent {
  @ViewChild('cart', { read: ViewContainerRef })
  private templateViewContainerRef: ViewContainerRef | null = null;
  private isCartActiveMounted: boolean = false;

  public get isEmptyCart(): boolean {
    if (!this.context.state.isEmptyCart) {
      this.lazyLoadComponent();
    }
    return this.context.state.isEmptyCart;
  }
  public get isOpenCart(): boolean {
    return this.context.state.isOpenCart;
  }

  constructor(
    private context: ContextService,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) { }

  private lazyLoadComponent() {
    if (this.isCartActiveMounted) return
    this.isCartActiveMounted = true;
    import('@pages/shell/cart-active/cart-active.component').then(({ CartActiveComponent }) => {
      if (!this.templateViewContainerRef) return
      const component = this.componentFactoryResolver.resolveComponentFactory(CartActiveComponent);
      this.templateViewContainerRef.createComponent(component);
    });
  }
}
