import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/shared.module';
import { AsideComponent } from './aside/aside.component';
import { CartInactiveComponent } from './cart-inactive/cart-inactive.component';
import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';
import { NavComponent } from './nav/nav.component';
import { ShellComponent } from './shell/shell.component';
@NgModule({
  declarations: [
    ShellComponent,
    LogoComponent,
    FooterComponent,
    AsideComponent,
    NavComponent,
    CartInactiveComponent,
  ],
  imports: [RouterModule, SharedModule,],
  exports: [ShellComponent],
})
export class ShellModule { }

