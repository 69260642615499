
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ContextService } from '@context';
import { TopBannerItem } from '@models/top-banner.model';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
@Injectable()

export class AdvertiseService {
  private _loading: boolean = false;

  public get isLoading(): boolean {
    return this._loading;
  }
  public set isLoading(value: boolean) {
    this._loading = value;
  }

  constructor(
    private db: AngularFirestore,
    private context: ContextService,
  ) { }
  private sortBanners(arr: TopBannerItem[]): TopBannerItem[] {
    return arr.slice().sort((a, b) => a.order - b.order)
  }

  public list(): Observable<TopBannerItem[]> {
    if (!this.context.state.banners) {
      return this.db.collection<TopBannerItem>('top-banners', ref => ref.where('enabled', '==', true))
        .valueChanges({ idField: 'id' })
        .pipe(
          take(2),
          map((banners: TopBannerItem[]) => this.sortBanners(banners)),
          tap((banners: TopBannerItem[]) => this.context.setState({ banners })
          ),
        )
    } else {
      return of(this.context.state.banners)
    }
  }
}


