import { Component } from '@angular/core';
import { ContextService } from '@context';
import { RepositoryService } from '@core/services/repository.service';
import { CategoryItem } from '@models/category-product.model';
import { MenuItem } from '@models/menu-item.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent {
  public listCategories$: Observable<CategoryItem[]>;

  public get myOrder(): MenuItem | undefined {
    if (this.context.state.isOrderPage) return undefined
    return this.context.navigationRoutes.find(o => o.id === 9)
  }


  constructor(private context: ContextService, private repositoryService: RepositoryService) {
    this.listCategories$ = this.repositoryService.categoriesList();
  }


  public getActiveCategory() {
    return this.context.state.activeCategory;
  }

  public setActiveCategory(id: any) {
    this.context.setState({ activeCategory: id });
  }
}
