import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SEOItem } from '@models/seo.model';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    @Inject(DOCUMENT) private dom: Document
  ) { }

  public generateTags(tags: SEOItem): void {
    const defaultKeyWords = ['суши', 'плевен', 'храна', 'доставка', 'ninja',];
    const defaultDescription = 'Най-доброто суши в плевен, премиум храна и бърза доставка';
    const defaultImage = '';

    this.title.setTitle(tags.title);

    const metaTags = [
      { name: 'description', content: tags.description ? tags.description : defaultDescription, 'data-id': '' },
      { name: 'keywords', content: tags.keywords ? tags.keywords.join(', ') : defaultKeyWords.join(', '), 'data-id': '' },
      // Open Graph
      { name: 'og:url', content: `https://ninjasushi.bg/${this.router.url}`, 'data-id': '' },
      { name: 'og:title', content: tags.title, 'data-id': '' },
      { name: 'og:description', content: tags.description ? tags.description : defaultDescription, 'data-id': '' },
      { name: 'og:image', content: tags.image ? tags.image : defaultImage, 'data-id': '' },
      // Twitter Card
      { name: 'twitter:card', content: 'summary', 'data-id': '' },
      { name: 'twitter:site', content: '@ninjasushi', 'data-id': '' },
    ];

    const existingMetaTags = Array.from(this.dom.querySelectorAll('meta[content][data-id]'));

    if (existingMetaTags && existingMetaTags.length) {
      for (const elRef of existingMetaTags) {
        elRef.remove()
      }
    }

    this.meta.addTags(metaTags);

    if (tags.canonical) {
      this.updateCanonicalUrl(tags.canonical);
    }
  }

  private updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
  public preloadImage(urlOrigin: string, urlOptional?: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    const element = this.dom.createElement('link') as HTMLLinkElement;
    head.appendChild(element);
    element.setAttribute('rel', 'preload');
    element.setAttribute('href', urlOrigin);
    if (urlOptional) {
      element.setAttribute('imagesrcset', urlOptional);
    }
    element.setAttribute('as', 'image');
    element.setAttribute('imagesizes', '100vw');
  }
}
