import { NgModule } from '@angular/core';
import { ScrollHorizontalDirective } from './scroll-horizontal.directive';
import { TouchEventsDirective } from './touch-events.directive';

const directives: any = [
  ScrollHorizontalDirective,
  TouchEventsDirective
];
@NgModule({
  declarations: [...directives],
  imports: [],
  exports: [...directives],
})
export class DirectivesModule { }

