<footer>
  <div class="logo-section">
    <section class="logo" routerLink="/">
      <img src="/assets/logo/full-logo.svg" width="0" height="0" alt="full logo" loading="lazy" />
    </section>
    <p class="mat-caption privacy-link" routerLink="/privacy-policy">Политика за поверителност</p>
  </div>

  <section class='location'>
    <p class='mat-display-3'>Адрес</p>
    <ul class="mat-subheading-1">
      <li>Работно време</li>
      <li>Понеделник - Събота - 11:30ч. до 22:00ч.</li>
      <li>Почивен ден - Неделя</li>
      <li>ул. "Пирин" 24</li>
      <li>гр. Плевен, България</li>
      <li><a class="primary" href="tel:+359876606720">+359 876 606 720</a></li>
      <li><a class="primary" href="tel:++35964977577">+359 64 977577</a></li>
    </ul>
  </section>
  <section class='social'>
    <p class='mat-display-3'>Последвайте ни</p>
    <div class="social-list mat-subheading-1">
      <a href="https://www.facebook.com/Ninja-Sushi-BG-105008168421108" aria-label="facebook" target="_blank"
        rel="noopener noreferrer">
        <img class="icon-img" src="/assets/new-icons/face.png" width="0" height="0" alt="social media facebook icon"
          loading="lazy" />
      </a>
      <a href="https://www.instagram.com/ninjasushi.bulgaria/" aria-label="instagram" target="_blank"
        rel="noopener noreferrer">
        <img class="icon-img" src="/assets/new-icons/insta.png" width="0" height="0" alt="social media instagram icon"
          loading="lazy" />
      </a>
    </div>
  </section>
  <button class='arrow outlined-button mat-button' (click)="handleClick()">▲</button>
</footer>
