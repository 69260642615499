import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ContextService } from "@context";
import { RepositoryService } from "@core/services/repository.service";
import { first } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CategoriesGuard implements CanActivate {

  constructor(private context: ContextService, private repositoryService: RepositoryService) { }

  async canActivate(): Promise<boolean> {
    if (this.context.state.categories && this.context.state.categories.length) return true;

    await this.repositoryService.categoriesList().pipe(first()).toPromise();

    return true;
  }
}
