import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { ContextService } from '@context';
import { formatForUrl } from '@core/utilities';
import { CategoryItem } from '@models/category-product.model';
import { IngredientsItem } from '@models/ingredients-item.model';
import { ProductItem } from '@models/product-item.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';
import { PromoCode } from '@models/PromoCode';

@Injectable()
export class RepositoryService {
  private _loading: boolean = false;

  public set isLoading(value: boolean) {
    this._loading = value;
  }
  public get isLoading(): boolean {
    return this._loading
  }

  constructor(
    private db: AngularFirestore,
    private context: ContextService,
    private utilities: UtilitiesService,
  ) { }

  // CATEGORIES
  public categoriesList(): Observable<CategoryItem[]> {
    const storeKey = `categories`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    return this.db.collection<CategoryItem>('categories', ref => ref.where('enabled', '==', true))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(this.sortCategories),
        tap(categories => this.context.state.categories = categories),
        take(1),
      );
  }

  public getCategoriesFeatured(): Observable<any> {
    const { categories } = this.context.state;

    return of(categories.filter((category: CategoryItem) => category.featured))
  }

  public getCategoryFeaturedProducts(category: CategoryItem): Observable<ProductItem[]> {
    const storeKey = `${category.name}-featured-products`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    const productIds = category.productsIds.map(item => item.key);
    const idsChunks = this.utilities.chunkArray(productIds, 10);

    if (!productIds.length) {
      return this.getProductsByCategory(category, 999)
    }

    return combineLatest(idsChunks.map(chunk => this.getProductsByIds(chunk)))
      .pipe(
        map((arrays: ProductItem[][]) => [].concat.apply([], arrays as any)),
        map(this.sortProducts),
        tap(items => this.context.setState({ [storeKey]: items }))
      );
  }

  // PRODUCTS
  public getProductsFeatured(): Observable<ProductItem[]> {
    const storeKey = `featured-products`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    return this.db.collection<ProductItem>('products', ref => ref.where('isFeatured', '==', true).where('enabled', '==', true))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(products => this.sortProducts(products)),
        tap(products => this.context.setState({ [storeKey]: products })),
        take(1),
      )
  }

  public getProductsByCategory(category: CategoryItem, limit: number = 9999): Observable<ProductItem[]> {
    const storeKey = `category-${category.id}`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    return this.db.collection<ProductItem>('products', ref => ref.where('categoryId', '==', category.id).where('enabled', '==', true).limit(limit))
      .valueChanges({ idField: 'id' })
      .pipe(
        switchMap(products => {
          if (!products || !products.length) return this.getCategoryFeaturedProducts(category)
          return of(products)
        }),
        map(products => this.sortProducts(products)),
        tap(products => this.context.setState({ [category.id]: products })),
        take(1),
      )
  }

  public getProductByUrl(url: ProductItem['url']): Observable<ProductItem | null> {
    const storeKey = url;

    if (this.context.state[storeKey]) return of(this.context.state[storeKey])

    return this.db.collection<ProductItem | null>('products', ref => ref.where('url', '==', url).where('enabled', '==', true))
      .valueChanges({ idField: 'id' })
      .pipe(
        map((product: ProductItem[]) => {
          if (!product.length) return null
          return product[0]
        }),
        tap(product => this.context.setState({ [storeKey]: product })),
        take(1),
      );
  }

  public getProductsByTypes(types: Array<ProductItem['type']>, isFeatured: boolean = false): Observable<ProductItem[]> {
    const storeKey = `${formatForUrl(types.sort((a, b) => a.localeCompare(b)).join('-'))}-products-list`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    const refWhere = (ref: CollectionReference) => {
      if (isFeatured) {
        return ref.where('type', 'in', types).where('enabled', '==', true).where('isFeatured', '==', true);
      }

      return ref.where('type', 'in', types).where('enabled', '==', true);
    }


    return this.db.collection<ProductItem>('products', ref => refWhere(ref))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(products => this.sortProducts(products)),
        tap(items => this.context.setState({ [storeKey]: items })),
        take(1),
      )
  }

  private getProductsByIds(ids: Array<ProductItem['id']>): Observable<ProductItem[]> {
    return this.db.collection<ProductItem>('products', ref => ref.where('id', 'in', ids).where('enabled', '==', true))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(products => this.sortProducts(products)),
        take(1),
      )
  }

  // INGREDIENTS
  public getIngredients(product: ProductItem): Observable<IngredientsItem[]> {
    const storeKey = `${product.url}-ingredients`;

    if (this.context.state[storeKey] && this.context.state[storeKey].length) return of(this.context.state[storeKey])

    const ingredientsIds = product.ingredientsIds.map(item => item.key);
    const idsChunks = this.utilities.chunkArray(ingredientsIds, 10);

    return combineLatest(idsChunks.map(chunk => this.getIngredientsByIds(chunk)))
      .pipe(
        map((arrays: IngredientsItem[][]) => [].concat.apply([], arrays as any)),
        map(this.sortIngredients),
        tap(items => this.context.setState({ [storeKey]: items }))
      );
  }

  private getIngredientsByIds(ids: Array<IngredientsItem['id']>): Observable<IngredientsItem[]> {
    return this.db.collection<IngredientsItem>('ingredients', ref => ref.where('id', 'in', ids))
      .valueChanges({ idField: 'id' })
      .pipe(
        take(1),
      )
  }

  // PROMO CODES
  public checkPromoCode(promoCode: string): Observable<PromoCode> {
    return this.db.collection<PromoCode>('promo-codes', ref => ref.where('promoCode', '==', promoCode))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(promoCodes => this.getActivePromoCode(promoCodes)),
        take(1),
      )
  }

  private getActivePromoCode(promoCodes: PromoCode[]): PromoCode {
    if (!(promoCodes && promoCodes.length)) return {} as PromoCode;

    const activePromoCode = promoCodes.filter(promoCode => {
      if (promoCode.limit !== 0 && promoCode.usedCount >= promoCode.limit) {
        return false;
      }

      if (promoCode.expirationDate) {
        const now = new Date().setHours(0, 0, 0, 0);
        const expirationDate = new Date(promoCode.expirationDate).setHours(0, 0, 0, 0);

        if (now >= expirationDate) {
          return false;
        }
      }

      return true;
    });

    return activePromoCode && activePromoCode[0] || {};
  }

  // UTILITIES
  public sortCategories(arr: CategoryItem[]): CategoryItem[] {
    if (!arr.length) return [];
    return arr.slice().sort((a, b) => a.order - b.order || a.name.localeCompare(b.name))
  }
  private sortProducts(array: ProductItem[]): ProductItem[] {
    return array.sort((a, b) => {
      const a1 = a.daily_offer ? 1 : 0
      const b1 = b.daily_offer ? 1 : 0
      return b1 - a1 || a.title.localeCompare(b.title)
    })
  }
  private sortIngredients(array: IngredientsItem[]): IngredientsItem[] {
    return array.sort((a, b) => a.name.localeCompare(b.name))
  }
  public getBoxItem(): Observable<ProductItem> {
    return this.db.collection<ProductItem>('products', ref => ref.where('categoryId', '==', 8))
      .valueChanges({ idField: 'id' })
      .pipe(map(p => p[0]))
  }
}
