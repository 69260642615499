// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBEqkhxeqWVAAixrN0eA1tvoIUio2vanFE",
    authDomain: "ninja-brand-dev.firebaseapp.com",
    projectId: "ninja-brand-dev",
    storageBucket: "ninja-brand-dev.appspot.com",
    messagingSenderId: "806092908506",
    appId: "1:806092908506:web:bb381eb164bd29e4c742e3",
    measurementId: "G-CPJXHZMM07"
  },
  menu: {
    guest: [
      {
        id: 0,
        name: 'home',
        title: 'Начало',
        link: '/',
      },
      {
        id: 1,
        name: 'delivery',
        title: 'Доставка',
        link: '/delivery',
      },
      {
        id: 2,
        name: 'clients',
        title: 'Клиенти',
        link: '/clients',
      },
    ],
    user: [],
    admin: [
      {
        id: 999,
        title: 'Администрация',
        name: 'admin',
        link: '/admin',
      },
    ],
  },
  storage: 'https://firebasestorage.googleapis.com/v0/b/ninja-brand-dev.appspot.com/o/products%2F',
  CONNECT_SRC: '*',
  DEFAULT_APP_CONFIGURATION: {
    preOrderTimeStep: 15,
    preOrderMissStep: 0,

    cartExpirationHours: 24,
    cartDeliveryPrice: 0,


    startHour: 12,
    startMinutes: 0,
    endHour: 22,
    endMinutes: 0,
    offDays: [0, 1],

    orderMessage: "",

    tempOffDates: [],
    tempUnavailable: "",

    specialDates: [],
    specialDatesConfig: {},
    specialDatesDefaultStartHour: null,
    specialDatesDefaultStartMinutes: null,
    specialDatesDefaultEndHour: null,
    specialDatesDefaultEndMinutes: null,

    weekendDays: [],
    weekendDaysConfig: {},
    weekendDayDefaultStartHour: null,
    weekendDayDefaultStartMinutes: null,
    weekendDayDefaultEndHour: null,
    weekendDayDefaultEndMinutes: null,
  },
  WEEK_DICTIONARY: [
    { id: 0, localId: 7, name: 'Неделя' },
    { id: 2, localId: 2, name: 'Вторник' },
    { id: 3, localId: 3, name: 'Сряда' },
    { id: 4, localId: 4, name: 'Четвъртък' },
    { id: 5, localId: 5, name: 'Петък' },
    { id: 6, localId: 6, name: 'Събота' },
    { id: 1, localId: 1, name: 'Понеделник' },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
