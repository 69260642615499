import { Component, Input } from '@angular/core';
import { BottomBannerItem } from '@models/bottom-banner.model';

@Component({
  selector: 'app-bottom-banner',
  templateUrl: './bottom-banner.component.html',
  styleUrls: ['./bottom-banner.component.scss']
})
export class BottomBannerComponent {
  @Input() data: BottomBannerItem | null = null;
  constructor() { }
}
