<article
  class="product-card"
  [attr.data-id]="data.id"
  [attr.data-animation]="isAnimated"
  [attr.data-daily-offer]="isDailyOffer"
  [attr.data-category]="data.categoryId"
>
  <ng-container *ngIf="isDailyOffer">
    <i class="daily-offer">%</i>
  </ng-container>
  <picture
    class="card-img pointer"
    [routerLink]="detailsLink"
    routerLinkActive="active"
  >
    <ng-container *ngIf="data.imageToken && isBigImage">
      <source
        [attr.srcset]="getProductImage.webp['500']"
        media="(max-width: 600px)"
        type="image/webp"
      />
      <source
        [attr.srcset]="getProductImage.png['500']"
        media="(max-width: 600px)"
        type="image/png"
      />
    </ng-container>
    <source [attr.srcset]="getProductImage.webp['500']" type="image/webp" />
    <source [attr.srcset]="getProductImage.png['500']" type="image/png" />
    <img
      src="/assets/product/product-card-default.png"
      [width]="dimensions.width"
      [height]="dimensions.height"
      [attr.loading]="isLazy"
      [attr.alt]="data.title"
    />
  </picture>

  <ng-container *ngIf="data.isHot">
    <img
      class="card-hot"
      src="/assets/product/hot.svg"
      height="30"
      width="30"
      loading="lazy"
      alt="hot product"
    />
  </ng-container>
  <ng-container *ngIf="data.isVegan">
    <img
      class="card-vegan"
      src="/assets/product/vegan.svg"
      height="30"
      width="30"
      loading="lazy"
      alt="vegan product"
    />
  </ng-container>

  <a class="pointer card-title" [routerLink]="detailsLink">
    <h2 class="mat-display-1" [title]="data.title">{{ data.title }}</h2>
  </a>
  <ng-container *ngIf="data.isActive">
    <p class="mat-body-1 ingredients-list">
      <span class="card-weight" data-weight
        >{{ data.weight }} {{ metric }}</span
      >
      <span>{{ ingredients }}</span>
    </p>
  </ng-container>
  <ng-container *ngIf="!data.isActive">
    <p class="mat-body-1">
      <span class="card-weight">Продукта за момента не е наличен.</span>
    </p>
  </ng-container>
  <button
    class="card-button contained-button product-add"
    [attr.data-color]="buttonColor"
    (click)="handleOnClick()"
    [disabled]="!data.isActive"
  >
    Добави
  </button>
  <span class="card-price mat-headline">{{ price }} лв</span>
</article>
