import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContextService } from '@context';

@Component({
  selector: 'app-meditate-modal',
  templateUrl: './meditate-modal.component.html',
  styleUrls: ['./meditate-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeditateModalComponent implements OnInit {

  constructor(
    private context: ContextService,
  ) { }

  ngOnInit(): void { }

  public handleClose(event: any, modalName: string): void {
    if (event.target.dataset.source !== 'root') return

    this.context.setState({ [modalName]: false });
  }
}
