<section class="top-banner-root" style="--columns: {{data?.length}};" (touchstart)="touchStart($event)"
  (touchend)="touchEnd($event)">
  <ng-container *ngIf="!isXS">
    <div #banners class="banner-list scrollable">
      <section *ngFor="let banner of data; index as i;" class="banner-card" [attr.data-type]="bannerType"
        [style.--text-color]="banner.text_color" [routerLink]="banner.link">
        <picture class="picture">
          <source *ngIf="banner.image" [attr.srcset]="banner.image.webp" type="image/webp" />
          <source *ngIf="banner.image" [attr.srcset]="banner.image.png" type="image/png" />
          <img src="/assets/product/product-card-default.png" [width]="dimensions.width" [height]="dimensions.height"
            [attr.loading]="i === 0 ? 'eager' : 'lazy'" alt="top advertise banner" />
        </picture>
        <div class="text mat-display-3">
          <p *ngIf="!banner.desktopWithoutTitle" class="title">{{banner.title}}</p>
          <p *ngIf="!banner.desktopWithoutContent" class="content mat-headline-3">{{banner.content}}</p>
          <button *ngIf="!banner.desktopWithoutCTA" class="contained-button mat-button"
            data-color="primary">{{banner.cta || 'Научи повече'}}</button>
        </div>
      </section>
    </div>
  </ng-container>

  <ng-container *ngIf="isXS">
    <div #banners class="banner-list scrollable">
      <section *ngFor="let banner of data; index as i;" class="banner-card" [attr.data-type]="bannerType"
        [style.--text-color]="banner.text_color" [routerLink]="banner.link">
        <picture class="picture">
          <source *ngIf="banner.image_mobile" [attr.srcset]="banner.image_mobile.webp" media="(max-width: 600px)"
            type="image/webp" />
          <source *ngIf="banner.image_mobile" [attr.srcset]="banner.image_mobile.webp" media="(max-width: 600px)"
            type="image/png" />
          <img src="/assets/product/product-card-default.png" [width]="dimensions.width" [height]="dimensions.height"
            [attr.loading]="i === 0 ? 'eager' : 'lazy'" alt="top advertise banner" />
        </picture>
        <div *ngIf="!banner.mobileWithoutTitle || !banner.mobileWithoutContent || !banner.mobileWithoutCTA" class="text mat-display-3">
          <p *ngIf="!banner.mobileWithoutTitle" class="title">{{banner.title}}</p>
          <p *ngIf="!banner.mobileWithoutContent" class="content mat-headline-3">{{banner.content}}</p>
          <button *ngIf="!banner.mobileWithoutCTA" class="contained-button mat-button" data-color="primary">{{banner.cta
            || 'Научи повече'}}</button>
        </div>
      </section>
    </div>
  </ng-container>

  <!-- <div class="indicator-list">
    <span *ngFor="let item of data; let i = index " class="indicator-card" [attr.data-active]="activeBanner === i"
      (click)="handleClick(i)"></span>
  </div> -->
</section>
