<main class="app">
  <app-nav class="nav"></app-nav>
  <section class="root">
    <router-outlet></router-outlet>
    <app-footer class="footer"></app-footer>
  </section>
  <app-aside class="aside"></app-aside>

  <ng-container *ngIf="isEmptyCart">
    <app-cart-inactive class="cart-inactive" [attr.data-active]="isOpenCart"></app-cart-inactive>
  </ng-container>

  <section class="cart-active" [attr.data-active]="isOpenCart" [attr.data-empty]="isEmptyCart">
    <ng-template #cart></ng-template>
  </section>
</main>