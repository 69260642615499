<nav class="nav">
  <!-- Mobile drawer -->
  <ng-container *ngIf="isTablet">
    <app-icon
      class="hamburger-menu"
      src="/assets/icons/menu.svg"
      (click)="toggleDrawer($event)"
    >
    </app-icon>
    <section
      *ngIf="isDrawerOpen"
      class="nav-drawer-root"
      data-source="root"
      touchEvents
      (touchSwipe)="handleTouchSwipe($event)"
      (click)="toggleDrawer($event)"
    >
      <section class="nav-drawer">
        <app-accordion class="categories" toggle="default" [open]="true">
          <accordion-head>
            <p>Меню</p>
          </accordion-head>
          <accordion-body>
            <a
              *ngFor="let category of listCategories$ | async"
              class="menu-item"
              [attr.data-item]="
                category.id === getActiveCategory() ? 'active' : 'inactive'
              "
              (click)="setActiveCategory(category.id)"
              [routerLink]="category.url"
              routerLinkActive="active"
            >
              <app-icon class="icon" [src]="category.image"></app-icon>
              <span class="mat-button">{{ category.name }}</span>
            </a>
          </accordion-body>
        </app-accordion>
        <nav class="nav">
          <span
            *ngFor="let option of navigationRoutes"
            class="nav-item mat-display-1"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span
              data-source="root"
              [routerLink]="option.link"
              (click)="toggleDrawer($event)"
              >{{ option.title }}</span
            >
          </span>
        </nav>
      </section>
    </section>
  </ng-container>

  <!-- Logo section -->
  <section class="logo" routerLink="/">
    <img src="/assets/logo/icon-logo.svg" width="0" height="0" alt="logo" />
    <ng-container *ngIf="!isMobile">
      <img src="/assets/logo/ninja-logo.svg" width="0" height="0" alt="logo" />
      <img src="/assets/logo/sushi-logo.svg" width="0" height="0" alt="logo" />
    </ng-container>
  </section>

  <!-- Desktop navigation links -->
  <ng-container *ngIf="!isTablet">
    <section class="top-nav-list mat-button">
      <a
        *ngFor="let option of navigationRoutes"
        class="list-item effect"
        [routerLink]="option.link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span>{{ option.title }}</span>
      </a>
    </section>
  </ng-container>

  <!-- Desktop additional links -->
  <section class="social">
    <ng-container *ngIf="!isTablet">
      <div class="social-item">
        <a
          href="https://www.facebook.com/Ninja-Sushi-BG-105008168421108"
          aria-label="facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="icon-img"
            src="/assets/new-icons/face.png"
            width="0"
            height="0"
            alt="social media facebook icon"
          />
        </a>
      </div>
      <div class="social-item">
        <a
          href="https://www.instagram.com/ninjasushi.bulgaria/"
          aria-label="instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="icon-img"
            src="/assets/new-icons/insta.png"
            width="0"
            height="0"
            alt="social media instagram icon"
          />
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="isTablet">
      <div class="social-item">
        <a
          href="https://www.google.com/maps/search/?api=1&query=ninja+sushi+pleven"
          aria-label="map"
          target="_blank"
          rel="noopener noreferrer"
        >
          <app-icon class="icon" src="/assets/icons/map-pin.svg"></app-icon>
        </a>
      </div>
    </ng-container>

    <app-accordion class="phone dropdown icon" toggle="focus" icon="">
      <accordion-head>
        <app-icon src="/assets/new-icons/phone_ring.png"></app-icon>
      </accordion-head>
      <accordion-body>
        <a class="mat-button phone-number first" href="tel:+359876606720"
          >+359 876 606 720</a
        >
        <a class="mat-button phone-number second" href="tel:++35964977577"
          >+359 64 977577</a
        >
      </accordion-body>
    </app-accordion>

    <ng-container *ngIf="isTablet">
      <app-icon
        class="social-item icon cart"
        src="/assets/new-icons/cart.png"
        (click)="toggleCart()"
        [attr.data-count]="countCart"
      >
      </app-icon>
    </ng-container>
  </section>
</nav>
