<button
  *ngIf="buttons"
  aria-label="previous item"
  area-controls="carousel-controls"
  class="root-button | flat-button"
  data-direction="left"
  data-color="text"
  type="button"
  (click)="handleScroll(null, true)"
>
  <span
    class="icon"
    style="--icon-url: url(/assets/icons/chevron-left.svg)"
  ></span>
</button>

<button
  *ngIf="buttons"
  aria-label="next item"
  area-controls="carousel-controls"
  class="root-button | flat-button"
  data-direction="right"
  data-color="text"
  type="button"
  (click)="handleScroll(null, false)"
>
  <span
    class="icon"
    style="--icon-url: url(/assets/icons/chevron-right.svg)"
  ></span>
</button>

<section id="carousel-controls" class="root-list">
  <ng-content></ng-content>
</section>

<section *ngIf="indicators && pages > 1" class="root-indicators">
  <nav #indicatorsRef class="indicators-list" data-indicators role="tablist">
    <button
      *ngFor="let indicator of indicatorsArray; index as i"
      class="indicator-card"
      role="tab"
      title="item {{ i + 1 }}"
      area-controls="carousel-controls"
    ></button>
  </nav>

  <span
    #indicatorsBadgeRef
    *ngIf="indicatorsBadge && maxItems < pages"
    class="indicators-badge caption-semibold"
    >/</span
  >
</section>
