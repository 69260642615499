import { Injectable } from '@angular/core';
@Injectable()

export class NotificationService {

  constructor() {
    if (typeof window === 'undefined' || !window.hasOwnProperty('navigator') || !window.navigator.hasOwnProperty('serviceWorker')) return
    window.navigator.serviceWorker.register('/sw.js');
  }

  public get isDefault(): boolean {
    if (typeof window === 'undefined') return false
    return (Notification.permission === 'default')
  }

  public initializationNotification(): void {
    if (typeof window === 'undefined' || !("Notification" in window) || !(Notification.permission === 'default')) return
    Notification.requestPermission();
  }

  public showNotification(text: string): void {
    if (typeof window === 'undefined' || Notification.permission !== 'granted') return
    window.navigator.serviceWorker.ready
      .then(function (registration) {
        registration.showNotification(text, {
          body: 'Ninjasushi',
          icon: '/assets/logo/icon-logo.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: 'ninjasushi'
        });
      })
  }
}