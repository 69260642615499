<section class="cart empty-cart">
  <ng-container *ngIf="isTablet">
    <span class="mat-title primary close" (click)="closeCart()">Затвори</span>
  </ng-container>

  <div class="content">
    <app-icon src="/assets/new-icons/cart.png" fontSize="4rem" color="rgba(0, 0, 0, 0.6)"></app-icon>
    <p class="mat-body-1 h4">Добави продукт в кошницата, при нас всичко е много вкусно!</p>
  </div>

  <div class="categories-list mat-button">
    <a *ngFor="let category of listCategories$ | async" class="list-item" [routerLink]="category.url"
      routerLinkActive="active" (click)="closeCart()">
      <app-icon [src]="category.image" fontSize="2em"></app-icon>
      <span class="item-title">{{category.name}}</span>
    </a>
  </div>
</section>
