import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContextService } from '@context';
import { environment } from '@env';
import { CategoryItem } from '@models/category-product.model';
import { ImageURLItem } from '@models/image-item.model';
import { ProductDefaultValues, ProductItem } from '@models/product-item.model';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent {
  @Input() data: ProductItem = ProductDefaultValues;
  @Input() animation: boolean = this.data.categoryId !== 6;
  @Input() big_image: boolean = false;
  @Input() lazy: boolean = true;

  public get price(): string {
    return Number(this.data.price).toFixed(2);
  }

  public get isAnimated(): boolean {
    return this.animation;
  }
  public get isBigImage(): boolean {
    return this.big_image;
  }
  public get isLazy(): string {
    return this.lazy ? 'lazy' : 'eager';
  }
  public get metric(): string {
    return this.data?.metric || 'гр'
  }
  public get detailsLink(): string {
    const category = this.context.state.categories.find((c: CategoryItem) => c.id === this.data.categoryId);

    return `${category?.url}${this.data?.url}`
  }
  public get isDailyOffer(): boolean {
    if (!this.data.daily_offer) return false
    return this.data.daily_offer;
  }
  public get buttonColor(): string {
    if (!this.data.daily_offer) return 'accent'
    return 'primary'
  }
  public get getProductImage(): ImageURLItem {
    const { storage } = environment;
    return {
      png: {
        '500': `${storage}${this.context.formatForImageName(this.data.id.toString())}_500x500.png?alt=media&token=${this.data.imageToken}`,
        '900': `${storage}${this.context.formatForImageName(this.data.id.toString())}_900x900.png?alt=media&token=${this.data.imageToken}`,
      },
      webp: {
        '500': `${storage}${this.context.formatForImageName(this.data.id.toString())}_500x500.webp?alt=media&token=${this.data.imageToken}`,
        '900': `${storage}${this.context.formatForImageName(this.data.id.toString())}_900x900.webp?alt=media&token=${this.data.imageToken}`,
      }
    }
  }
  get dimensions(): any {
    if (this.big_image) {
      return {
        width: 900,
        height: 600,
      }
    }

    return {
      width: 500,
      height: 333,
    }
  }

  public get ingredients() {
    if (!this.data.ingredientsIds.length) return ''

    return '- ' + this.data.ingredientsIds.map(x => x.text).join(', ').toLowerCase().trim();
  }

  constructor(
    private context: ContextService,
  ) { }

  public handleOnClick() {
    if (!this.data.enabled || !this.data.isActive) return

    if (this.context.state.isEmptyCart) {
      this.context.setState({ isEmptyCart: false });
    }

    this.context.addToCart(this.data);
  }
}
