import { Component, OnInit } from '@angular/core';
import { ContextService } from '@context';

@Component({
  selector: 'app-day-off-modal',
  templateUrl: './day-off-modal.component.html',
  styleUrls: ['./day-off-modal.component.scss']
})
export class DayOffModalComponent implements OnInit {

  constructor(
    private context: ContextService
  ) { }

  ngOnInit(): void {
  }

  public handleClose(event: any, modalName: string): void {
    if (event.target.dataset.source !== 'root') return

    this.context.setState({ [modalName]: false });
  }
}
