import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { CookieModalComponent } from './cookie/cookie-modal.component';
import { DayOffModalComponent } from './day-off/day-off-modal.component';
import { MeditateModalComponent } from './meditate/meditate-modal.component';
import { ModalsRootComponent } from './modals.component';

const components: any = [
  MeditateModalComponent,
  CookieModalComponent,
  DayOffModalComponent,
  ModalsRootComponent,
];

@NgModule({
  declarations: [...components],
  imports: [ComponentsModule, RouterModule],
  exports: [ModalsRootComponent],
})

export class ModalsModule { }

