import { Component, OnInit } from '@angular/core';
import { ContextService } from '@context';
import { RepositoryService } from '@core/services/repository.service';
import { SeoService } from '@core/services/seo.service';
import { AdvertiseService } from '@core/services/top-banner.service';
import { BottomBannerItem } from '@models/bottom-banner.model';
import { CategoryItem } from '@models/category-product.model';
import { ProductItem } from '@models/product-item.model';
import { TopBannerItem } from '@models/top-banner.model';
import { ScreenService } from '@root/screen.service';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public uspList$: Observable<{ title: string; image: string; content: string; }[]> = new Observable();
  public topBannerList$: Observable<TopBannerItem[] | null> = new Observable();
  public bottomBanner$: Observable<BottomBannerItem | null> = new Observable();
  public featuredRolls$: Observable<ProductItem[]> = new Observable();
  public featuredSushiSnacks$: Observable<ProductItem[]> = new Observable();
  public featuredDrinks$: Observable<ProductItem[]> = new Observable();
  public featuredDeserts$: Observable<ProductItem[]> = new Observable();
  public featureSets$: Observable<ProductItem[]> = new Observable();
  public featuredCategories$: Observable<CategoryItem[]> = new Observable();

  get map(): any {
    if (this.screen.state.isXS) {
      return { width: 400, height: 238, }
    } else {
      return { width: 1080, height: 764, }
    }
  }

  constructor(
    private context: ContextService,
    private repositoryService: RepositoryService,
    private seo: SeoService,
    private screen: ScreenService,
    private adService: AdvertiseService,
  ) {
    this.featuredCategories$ = this.repositoryService.getCategoriesFeatured();
    this.uspList$ = this.context.uspList$();
    this.bottomBanner$ = this.context.bottomOffer$();
    this.topBannerList$ = this.adService.list()
      .pipe(
        tap((banners: TopBannerItem[]) => {
          if (!banners.length) return

          if (this.context.state.isServer) {
            if (this.screen.state.isXS) {
              if (!banners[0].image_mobile || !banners[0].image_mobile.webp) return
              this.seo.preloadImage(banners[0].image_mobile.webp);
            } else {
              if (!banners[0].image || !banners[0].image.webp) return
              this.seo.preloadImage(banners[0].image.webp);
            }
          }
        })
      );
  }

  ngOnInit(): void {
    this.seo.generateTags({
      title: 'Ninja Sushi - Pleven | Поръчка и доставка на суши в Плевен',
      description: 'НАЙ-ДОБРОТО СУШИ В ПЛЕВЕН, ПРЕМИУМ ХРАНА И БЪРЗА ДОСТАВКА | Ninja Sushi - Pleven',
      canonical: 'https://ninjasushi.bg/',
    });
    if (this.context.state.isBrowser && this.context.state.activeCategory !== 99) {
      this.context.setState({ activeCategory: 99 });
    }
  }

  public trackByFn(index: number, item: ProductItem): string {
    return item.id.toString();
  }

  public lazyImage(index: number): boolean {
    const mobile_exceptions = -1;
    const desktop_exceptions = 4;

    if (this.screen.state.isXS) {
      return (index > mobile_exceptions)
    }

    return (index > desktop_exceptions)
  }
}
