import { Component, Input } from '@angular/core';
import { RepositoryService } from '@core/services/repository.service';
import { CategoryItem } from '@models/category-product.model';
import { ProductItem } from '@models/product-item.model';
import { ScreenService } from '@root/screen.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'featured-category',
  templateUrl: './featured-category.component.html',
  styleUrls: ['./featured-category.component.scss']
})
export class FeaturedCategoryComponent {
  @Input() category!: CategoryItem;
  public products$: Observable<ProductItem[]> = of([]);

  constructor(
    private repository: RepositoryService,
    private screen: ScreenService,
  ) { }

  ngOnInit(): void {
    this.products$ = this.repository.getCategoryFeaturedProducts(this.category);
  }

  public trackByFn(index: number, item: ProductItem): string {
    return item.id.toString();
  }

  public lazyImage(index: number): boolean {
    const mobile_exceptions = -1;
    const desktop_exceptions = 4;

    if (this.screen.state.isXS) {
      return (index > mobile_exceptions)
    }

    return (index > desktop_exceptions)
  }
}
