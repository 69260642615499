import { Component } from '@angular/core';

@Component({
  selector: 'app-product-card-placeholder',
  templateUrl: './product-card-placeholder.component.html',
  styleUrls: ['./product-card-placeholder.component.scss']
})
export class ProductCardPlaceholderComponent {

  constructor() { }
}
