import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { ContextService } from '@context';
import { ScreenService } from '@root/screen.service';
@Directive({
  selector: '[scrollHorizontal]'
})

export class ScrollHorizontalDirective implements AfterViewInit {
  private lastChildRight: number = 0;
  private lastChildIndex: number = 0;
  private childWidth: number = 0;
  private containerRight: number = 0;
  private containerLeft: number = 0;

  @HostListener('scroll', ['$event.target']) onScroll(elRef: HTMLElement): void {
    if (this.context.state.isServer || this.screen.state.isMD) return

    const currentRight = elRef.children[this.lastChildIndex].getBoundingClientRect().right;
    const currentLeft = elRef.children[0].getBoundingClientRect().left;
    if (Math.floor(currentRight) > Math.floor(this.containerRight)) {
      elRef.dataset.scrollRight = "block";
    } else {
      elRef.dataset.scrollRight = "none";
    }

    if (Math.floor(currentLeft) < Math.floor(this.containerLeft)) {
      elRef.dataset.scrollLeft = "block";
    } else {
      elRef.dataset.scrollLeft = "none";
    }
  }

  constructor(private el: ElementRef, private context: ContextService, private screen: ScreenService) { }

  ngAfterViewInit(): void {
    if (this.context.state.isServer || this.screen.state.isMD) return
    this.containerRight = this.el.nativeElement.getBoundingClientRect().right;
    this.containerLeft = this.el.nativeElement.getBoundingClientRect().left;
    this.lastChildIndex = this.el.nativeElement.children.length - 1;
    this.lastChildRight = this.el.nativeElement.children[this.lastChildIndex].getBoundingClientRect().right;

    if (Math.floor(this.lastChildRight) < Math.floor(this.containerRight)) {
      this.el.nativeElement.dataset.scrollLeft = "none";
      this.el.nativeElement.dataset.scrollRight = "none";
      return
    }
    const computedStyle = window.getComputedStyle(this.el.nativeElement);
    const gap = Number(computedStyle.columnGap.replace('px', ''));
    this.childWidth = this.el.nativeElement.children[0].getBoundingClientRect().width + gap;
    this.el.nativeElement.parentElement.children[1].addEventListener('click', (event: any) => this.handleScroll(false, event));
    this.el.nativeElement.parentElement.children[2].addEventListener('click', (event: any) => this.handleScroll(true, event));
    this.el.nativeElement.dataset.scrollLeft = "none";
    this.el.nativeElement.dataset.scrollRight = "block";
  }

  private handleScroll(isLeft: boolean = true, event: any): void {
    const currentScroll = event.target.parentElement.children[0].scrollLeft;
    const scrollLeft = isLeft ? currentScroll + this.childWidth : currentScroll - this.childWidth;
    this.el.nativeElement.scroll({
      left: scrollLeft,
      behavior: 'smooth'
    });
  }
}
