import { Component } from '@angular/core';
import { ContextService } from '@context';
import { CookieService } from '@core/services/cookie.service';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss'],
})
export class CookieModalComponent {

  constructor(
    private context: ContextService,
    private cookie: CookieService,
  ) { }

  public handleCloseAncAcceptCookies(event: any): void {
    if (event.target.dataset.source !== 'root') return
    this.cookie.set(new Date().valueOf().toString());
    this.context.setState({ isCookieModalOpen: false });
  }
}
