import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from '@context';
import { TopBannerItem } from '@models/top-banner.model';
import { ScreenService } from '@root/screen.service';
@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements AfterViewInit {
  @Input() data: TopBannerItem[] | null = null;
  @ViewChild('banners', { read: ElementRef })
  private scrollable: ElementRef<any> | null = null;
  private screenX = 0;
  private screenY = 0;
  private minScreenDistance = 20;
  private tolerance = 0.4;
  private autoPlay: number = 0;
  private timeoutInterval: any;
  public activeBanner: number = 0;

  get isXS(): boolean {
    return this.screen.state.isXS
  }

  get bannerType(): string {
    if (this.screen.state.isXS) {
      return 'half'
    }
    return 'full'
  }

  get banners(): TopBannerItem[] | null {
    return this.data
  }

  get dimensions(): any {
    if (this.screen.state.isXS) {
      return { width: 600, height: 450, }
    }
    return { width: 1330, height: 500, }
  }

  constructor(private context: ContextService, private router: Router, private screen: ScreenService) { }

  ngAfterViewInit() {
    if (this.context.state.isServer) return
    this.timeoutInterval = this.handleAutoScroll();
    this.autoPlay = this.scrollable?.nativeElement.children.length;
  }

  public handleNavigate(link: string): void {
    this.router.navigate([link]);
  }
  public handleClick(index: number): void {
    if (!this.banners) return

    clearInterval(this.timeoutInterval);
    this.timeoutInterval = this.handleAutoScroll();
    this.scrollElement(index);
    this.autoPlay = index + this.banners.length;
  }
  private handleAutoScroll(interval: number = 10000): any {
    return setInterval(() => {
      if (!this.banners) return

      const currentIndex = this.autoPlay % this.banners.length;
      this.scrollElement(currentIndex);
      this.autoPlay = this.autoPlay + 1;
    }, interval);
  }
  private scrollElement(index: number): void {
    if (this.context.state.isServer) return
    const theIncrement = this.scrollable?.nativeElement.children[0].getClientRects()[0]?.width * index;
    this.activeBanner = index;
    this.scrollable?.nativeElement.scrollTo({ left: theIncrement, behavior: 'smooth' });
  }
  private touchAction(direction = ''): void {
    if (!direction || !this.banners) return
    if (direction === 'left' && this.activeBanner < this.banners.length - 1) {
      this.handleClick(this.activeBanner + 1)
    }
    if (direction === 'right' && this.activeBanner >= 1) {
      this.handleClick(this.activeBanner - 1)
    }
  }
  public touchStart(event: any): void {
    const newScreenX = event.changedTouches[0].screenX;
    const newScreenY = event.changedTouches[0].screenY;
    this.screenX = newScreenX;
    this.screenY = newScreenY;
  }
  public touchEnd(event: any): void {
    const newScreenX = event.changedTouches[0].screenX;
    const newScreenY = event.changedTouches[0].screenY;
    const screenDistanceX = Math.abs(newScreenX - this.screenX);
    const screenDistanceY = Math.abs(newScreenY - this.screenY);
    const diagonalToleranceX = screenDistanceY / screenDistanceX;
    const diagonalToleranceY = screenDistanceX / screenDistanceY;
    if (this.minScreenDistance < screenDistanceX && this.tolerance >= diagonalToleranceX) {
      if (this.screenX > newScreenX) this.touchAction('left');
      if (this.screenX < newScreenX) this.touchAction('right');
      this.screenX = 0;
      return
    }
    if (this.minScreenDistance < screenDistanceY && this.tolerance >= diagonalToleranceY) {
      if (this.screenY < newScreenY) this.touchAction('down');
      if (this.screenY > newScreenY) this.touchAction('up');
      this.screenY = 0;
      return
    }
  }
}
