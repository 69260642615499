import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { ConfigurationService } from '@configurations';
import { environment } from '@env';
import { BottomBannerItem } from '@models/bottom-banner.model';
import { CartItem } from '@models/cart-item.model';
import { MenuItem } from '@models/menu-item.model';
import { ProductItem } from '@models/product-item.model';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { BehaviorSubject, Observable, of } from 'rxjs';

const uspDictionary = [
  { title: 'свежо', image: 'fresh', content: 'Гарантираме свежестта на продуктите' },
  { title: 'суши шеф', image: 'chef', content: 'Освен класиките предлагаме и оригинални рецепти' },
  { title: 'продукти', image: 'products', content: 'Ние не използваме подобрители на вкуса. Купуваме всички продукти от доверени доставчици' },
  { title: 'чиста кухня', image: 'kitchen', content: 'целият процес на готвене се извършва в съответствие с всички санитарни стандарти' },

];
const bottomOffer = {
  id: 1,
  image: '/assets/banners/bottom.webp',
  image_mobile: '/assets/banners/bottom-mobile.webp',
  // title: 'Title is just a title 4',
  // content: 'Test text for default testing purposes 1',
  // link: '/#',
  order: 0,
};
@Injectable()

export class ContextService {
  private readonly initialState: any = {
    isLoading: false,
    isOpenCart: false,
    isOrderPage: false,
    snackBar: false,
    categories: []
  };
  private cartItems: CartItem[] = this.getCart();
  public readonly cartItemsList$: BehaviorSubject<CartItem[]> = new BehaviorSubject<CartItem[]>(this.cartItems);
  public readonly state$: BehaviorSubject<any> = new BehaviorSubject<any>(this.initialState);
  public navigationRoutes: MenuItem[] = environment.menu.guest;

  constructor(
    private transferState: TransferState,
    private config: ConfigurationService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) protected request: Request,
  ) {
    if (isPlatformServer(this.platformId)) {
      this.setState({
        isBot: request.headers['x-app-bot'] === 'true',
        isBrowser: false,
        isServer: true,
        isEmptyCart: true
      });
    }
    if (isPlatformBrowser(this.platformId)) {
      this.setState({
        ...this.initialState,
        ...transferState.get(makeStateKey<Object>('state'), this.initialState),
        isNotificationModalOpen: false,
        isTempUnavailableModalOpen: false,
        isBrowser: true,
        isServer: false,
        isEmptyCart: !this.getCart().length,
      });
    }
  }

  public uspList$(): Observable<{ title: string; image: string; content: string; }[]> {
    return of(uspDictionary);
  }
  public bottomOffer$(): Observable<BottomBannerItem | null> {
    return of(bottomOffer);
  }

  //CART FUNCTIONALITY
  private setCart(): void {
    if (isPlatformServer(this.platformId)) return

    window.localStorage.setItem('cart-items', JSON.stringify(this.cartItems.filter(c => c.item.categoryId !== 8)));
    window.localStorage.setItem('cart-items-date', new Date().getTime().toString());
  }
  private getCart(): CartItem[] {
    if (isPlatformServer(this.platformId)) return []
    const time = window.localStorage.getItem('cart-items-date');
    const timeParse = isNaN(Number(time)) ? 0 : Number(time);
    const hourDiff = Math.floor((new Date().getTime() - timeParse) / 1000 / 60 / 60);

    if (hourDiff >= this.config.configurations.cartExpirationHours) return []
    return JSON.parse(window.localStorage.getItem('cart-items') || '[]');
  }
  public addToCart(data: ProductItem, count: number = 1, notification: boolean = true): void {
    const product = this.cartItems.find(x => x.item.id === data.id) || false;

    if (!product) {
      this.cartItems.push({ item: data, count });
    } else {
      product.count = product.count + count;
    }
    notification && this.openSuccessSnackBar('Продуктът е добавен в кошницата.', 1400);
    this.setCart();
    this.cartItemsList$.next(this.cartItems);
  }
  public removeFromCart(data: ProductItem, count: number = 1): void {
    const product = this.cartItems.find(x => x.item.id === data.id) || false;

    if (product) {
      if (product.count > count) {
        product.count = product.count - count;
      } else {
        this.cartItems = this.cartItems.filter(x => x.item.id !== data.id);
        if (this.cartItems.filter(c => c.item.categoryId !== 8).length === 0) {
          this.setState({ isEmptyCart: true, isOpenCart: false });
        }
      }
    }
    this.setCart();
    this.cartItemsList$.next(this.cartItems);
  }
  public clearCart(): void {
    if (isPlatformServer(this.platformId)) return
    this.setState({ isEmptyCart: true, isOpenCart: false });
    this.cartItems = [];
    window.localStorage.removeItem('cart-items');
    window.localStorage.removeItem('cart-items-date');
    this.cartItemsList$.next(this.cartItems);
  }
  public countCart(): number {
    return this.cartItems.reduce((acc, item) => {
      return acc + item.count
    }, 0);
  }

  //SNACKBAR
  public openSuccessSnackBar(message: string, duration: number = 4000) {
    this.setState({ snackBar: true, snackBarSuccessful: true, snackMessage: message });
    setTimeout(() => {
      this.setState({ snackBar: false, snackBarSuccessful: false, snackMessage: '' });
    }, duration);
  }
  public openFailureSnackBar(message: string, duration: number = 6000) {
    this.setState({ snackBar: true, snackBarWarn: true, snackMessage: message });
    setTimeout(() => {
      this.setState({ snackBar: false, snackBarWarn: false, snackMessage: '' });
    }, duration);
  }

  //STATE
  public get state(): any {
    return this.state$.getValue()
  }
  protected getState(): Observable<any> {
    return this.state$;
  }
  public setState(newState: Partial<any>): void {
    const updatedState = { ...this.state$.getValue(), ...newState };
    this.state$.next(updatedState);
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(makeStateKey<Object>('state'), updatedState);
    }
  }

  //Utilities
  public formatForImageName(text: string): string {
    return text.toString().replace(/\s/g, '_').toLowerCase();
  }
}
