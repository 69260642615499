import { Injectable } from '@angular/core';

@Injectable()

export class CookieService {
  cookieName: string = 'accept-cookie';

  constructor() { }

  public set(data: string | null = null): void {
    if (!data || !this.cookieName) return

    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = this.cookieName + "=" + data + ";" + expires + ";path=/";
  };

  public get(): string {
    if (!this.cookieName) return ''

    const cookieValue = document.cookie.match('(^|;) ?' + this.cookieName + '=([^;]*)(;|$)');
    return cookieValue ? cookieValue[2] : '';
  }

  public remove(): void {
    if (!this.cookieName) return

    const expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = this.cookieName + "=;" + expires + ";path=/";
  }
}