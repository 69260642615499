import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[touchEvents]'
})
export class TouchEventsDirective {
  private defaultTouch = { x: 0, y: 0, time: 0 };
  private minimumTimeLeast: number = 500;
  private maximumMovementSize: number = 40;

  constructor() { }

  @Output() touchSwipe = new EventEmitter();
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event: any) {
    const touch = event.touches[0] || event.changedTouches[0];

    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x;
      const deltaY = touch.pageY - this.defaultTouch.y;
      const deltaTime = event.timeStamp - this.defaultTouch.time;

      if (deltaTime < this.minimumTimeLeast) {
        if (Math.abs(deltaX) > this.maximumMovementSize) {
          if (deltaX > 0) {
            this.doSwipeRight(event);
          } else {
            this.doSwipeLeft(event);
          }
        }

        if (Math.abs(deltaY) > this.maximumMovementSize) {
          if (deltaY > 0) {
            this.doSwipeDown(event);
          } else {
            this.doSwipeUp(event);
          }
        }

      }
    }
  }

  private doSwipeLeft(event: any): void {
    this.touchSwipe.next({ swipe: 'left', event });
  }
  private doSwipeRight(event: any): void {
    this.touchSwipe.next({ swipe: 'right', event });
  }
  private doSwipeUp(event: any): void {
    this.touchSwipe.next({ swipe: 'up', event });
  }
  private doSwipeDown(event: any): void {
    this.touchSwipe.next({ swipe: 'down', event });
  }
}
