<section class="modal-background blur" (click)="handleClose($event,'isDaysOff')" data-source="root">
  <section class="modal-root modal-dayOff-root mat-display-3">
    <div class="heading mat-display-3">Почивен ден</div>
    <p class="title mat-title">Уважаеми клиенти, заповядайте да разгледате нашето меню от вкусни предложения.</p>

    <section class="content">
      <p class="mat-headline">Работно време:</p>
      <p class="mat-title">Понеделник - Събота - 11:30ч. до 22:00ч.</p>
      <p class="mat-title">Почивен ден - Неделя</p>
    </section>
    <span (click)="handleClose($event,'isDaysOff')" class="actions contained-button" data-color="primary"
      data-source="root">Напред</span>
  </section>
</section>
