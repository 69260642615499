<nav class="aside">
  <a
    *ngFor="let category of listCategories$ | async"
    class="menu-item"
    [attr.data-item]="
      category.id === getActiveCategory() ? 'active' : 'inactive'
    "
    (click)="setActiveCategory(category.id)"
    [routerLink]="category.url"
    routerLinkActive="active"
    [attr.aria-label]="category.name"
  >
    <hr class="icon" style="--icon-url:url({{ category.image }})" />
    <span class="mat-button">{{ category.name }}</span>
  </a>
</nav>
<section *ngIf="myOrder" class="my-order">
  <p [routerLink]="myOrder.link">Моята поръчка</p>
</section>
<section class="shadow"></section>
