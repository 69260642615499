import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ConfigurationService } from '@configurations';
import { ContextService } from '@context';
import { CookieService } from '@core/services/cookie.service';
import { TimeService } from '@core/services/time.service';
@Component({
  selector: 'app-modals-root',
  template: `
            <ng-container *ngIf="isMeditateModalOpen">
              <app-meditate-modal></app-meditate-modal>
            </ng-container>

            <ng-container *ngIf="isDaysOff">
              <app-day-off-modal></app-day-off-modal>
            </ng-container>

            <ng-container *ngIf="isCookieModalOpen">
              <app-cookie-modal></app-cookie-modal>
            </ng-container>

            <ng-container *ngIf="isOrderModalOpen">
              <ng-template #OrderModalTemplate></ng-template>
            </ng-container>

            <ng-container *ngIf="isNotificationModalOpen">
              <ng-template #NotificationModalTemplate></ng-template>
            </ng-container>

            <section class="isTempUnavailableModalOpen" [attr.data-view]="isTempUnavailableModalOpen">
              <ng-template #TempUnavailableModalTemplate></ng-template>
            </section>
          `,
  styleUrls: ['./modals.component.scss'],
})

export class ModalsRootComponent {

  @ViewChild('NotificationModalTemplate', { read: ViewContainerRef })
  private NotificationViewContainerRef: ViewContainerRef | null = null;
  private isNotificationModalMounted: boolean = false;

  @ViewChild('OrderModalTemplate', { read: ViewContainerRef })
  private OrderViewContainerRef: ViewContainerRef | null = null;
  private isOrderModalMounted: boolean = false;

  @ViewChild('TempUnavailableModalTemplate', { read: ViewContainerRef })
  private TempUnavailableViewContainerRef: ViewContainerRef | null = null;
  private isTempUnavailableModalMounted: boolean = false;

  public get isOrderModalOpen(): boolean {
    if (this.context.state.isOrderModalOpen && !this.isOrderModalMounted) {
      this.lazyLoadOrder();
      this.isOrderModalMounted = true;
    }
    return this.context.state.isOrderModalOpen;
  }
  public get isNotificationModalOpen(): boolean {
    if (this.context.state.isNotificationModalOpen && !this.isNotificationModalMounted) {
      this.lazyLoadNotification();
      this.isNotificationModalMounted = true;
    }
    return this.context.state.isNotificationModalOpen;
  }
  public get isCookieModalOpen(): boolean {
    if (this.context.state.isServer) return false
    if (this.context.state.isCookieModalOpen === false) return false
    return !Boolean(this.cookieService.get());
  }
  public get isDaysOff(): boolean {
    if (this.context.state.isDaysOff === false) return false
    return this.time.isOffDay();
  }
  public get isMeditateModalOpen(): boolean {
    if (this.context.state.isMeditateModalOpen === false) return false
    return !this.time.isWorkingTime() && !this.time.isOffDay() && !this.config.configurations.tempUnavailable;
  }
  public get isTempUnavailableModalOpen(): boolean {
    if (this.context.state.isTempUnavailableModalOpen && !this.isTempUnavailableModalMounted) {
      this.lazyLoadTempUnavailable();
      this.isTempUnavailableModalMounted = true;
    }
    return this.context.state.isTempUnavailableModalOpen;
  }

  constructor(
    private context: ContextService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private time: TimeService,
    private cookieService: CookieService,
    private config: ConfigurationService,
  ) { }

  private lazyLoadNotification() {
    import('@modals/notification-request/notification.component').then(({ NotificationModalComponent }) => {
      const component = this.componentFactoryResolver.resolveComponentFactory(NotificationModalComponent);
      this.NotificationViewContainerRef?.createComponent(component);
    });
  }
  private lazyLoadOrder() {
    import('@modals/order/order-modal.component').then(({ OrderModalComponent }) => {
      const component = this.componentFactoryResolver.resolveComponentFactory(OrderModalComponent);
      this.OrderViewContainerRef?.createComponent(component);
    });
  }
  private lazyLoadTempUnavailable() {
    import('@modals/temp-unavailable/temp-unavailable.component').then(({ TempUnavailableComponent }) => {
      const component = this.componentFactoryResolver.resolveComponentFactory(TempUnavailableComponent);
      this.TempUnavailableViewContainerRef?.createComponent(component);
    });
  }
}
