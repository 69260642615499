<section class="modal-background blur" (click)="handleClose($event,'isMeditateModalOpen')" data-source="root">
  <section class="modal-root modal-working-root mat-display-3">
    <img class="icon-root" src="/assets/dialogs/meditation-ninja.png" width="0" height="0" alt="meditation ninja" />
    <div class="heading mat-display-3">В момента всички нинджи медитират</div>
    <section class="content">
      <p class="mat-headline">Работно време: 11:30 - 22:00</p>
    </section>
    <span (click)="handleClose($event,'isMeditateModalOpen')" class="actions contained-button" data-color="primary"
      data-source="root">Предварителна
      поръчка</span>
  </section>
</section>
