import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()

export class ScreenService {
  public readonly state$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) protected request: Request,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.setState({
        ...this.screenMedia(),
      });
    }
    if (isPlatformServer(this.platformId)) {
      this.deviceService.setDeviceInfo(request.headers['user-agent']);
      this.setState({
        isXL: this.deviceService.isDesktop(),
        isLG: this.deviceService.isDesktop(),
        isMD: this.deviceService.isTablet(),
        isSM: this.deviceService.isTablet(),
        isXS: this.deviceService.isMobile(),
      });
    }
  }

  //UTILITIES
  public screenMedia(): any {
    let screenSize = 'xs';
    const SCREEN_DICTIONARY: any = {
      xl: { isXL: true, isLG: false, isMD: false, isSM: false, isXS: false },
      lg: { isXL: true, isLG: true, isMD: false, isSM: false, isXS: false },
      md: { isXL: true, isLG: true, isMD: true, isSM: false, isXS: false },
      sm: { isXL: true, isLG: true, isMD: true, isSM: true, isXS: false },
      xs: { isXL: true, isLG: true, isMD: true, isSM: true, isXS: true },
    };

    if (window.innerWidth >= 600 && window.innerWidth <= 959) { screenSize = 'sm' }
    else if (window.innerWidth >= 960 && window.innerWidth <= 1279) { screenSize = 'md' }
    else if (window.innerWidth >= 1280 && window.innerWidth <= 1919) { screenSize = 'lg' }
    else if (window.innerWidth >= 1920) { screenSize = 'xl' }
    return SCREEN_DICTIONARY[screenSize];
  }

  //STATE
  public get state(): any {
    return this.state$.getValue();
  }
  protected getState(): Observable<any> {
    return this.state$;
  }
  public setState(newState: Partial<any>): void {
    const updatedState = { ...this.state$.getValue(), ...newState };
    this.state$.next(updatedState);
  }
}