import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-usp-card',
  templateUrl: './usp-card.component.html',
  styleUrls: ['./usp-card.component.scss']
})
export class UspCardComponent {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() image: string = '';

  public get imageLink(): string {
    return `/assets/new-icons/usp_${this.image}.png`
  }
  constructor() { }
}
