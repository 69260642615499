import { CategoryItem } from "./category-product.model";
import { SelectItem } from "./commons-models";

export type ProductType = 'хранителни продукти' |
  'безалкохолни напитки' |
  'алкохол' |
  'тестени изделия' |
  'сладкарски изделия' |
  'плодове' |
  'зеленчуци' |
  'други';

export const productTypeList: ProductType[] = [
  'хранителни продукти',
  'безалкохолни напитки',
  'алкохол',
  'тестени изделия',
  'сладкарски изделия',
  'плодове',
  'зеленчуци',
  'други'
]

export interface ProductItem {
  id: string | number;
  title: string;
  ingredientsIds: SelectItem[];
  type: ProductType;
  image?: {
    png: string,
    webp: string,
  };
  imageToken?: string,
  price: number;
  weight: number;
  isFeatured?: boolean;
  isActive: boolean;
  isHot: boolean;
  isVegan: boolean;
  categoryId: CategoryItem['id'];
  enabled: boolean;
  metric: string;
  category?: CategoryItem;
  daily_offer?: boolean;
  boxSize?: number;
  lastModified: number;
  keywords: string[];
  url: string;
};

export const ProductDefaultValues: ProductItem = {
  id: 0,
  type: 'хранителни продукти',
  title: 'Title',
  ingredientsIds: [],
  price: 0,
  weight: 0,
  isFeatured: false,
  isActive: true,
  isHot: false,
  isVegan: false,
  enabled: false,
  categoryId: '',
  keywords: [],
  lastModified: 0,
  metric: 'гр',
  url: '',
};
