import { Injectable, OnDestroy } from '@angular/core';
import { ConfigurationService } from '@configurations';
import { environment } from '@env';
import { ConfigurationItem, DayConfiguration } from '@models/configuration-item.model';
import { Subscription } from 'rxjs';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})

export class TimeService implements OnDestroy {
  private subscription: Subscription | null = null;
  private time: ConfigurationItem = environment.DEFAULT_APP_CONFIGURATION;

  private second: number = 1000;
  private minute: number = this.second * 60;
  private hour: number = this.minute * 60;
  private day: number = this.hour * 24;
  private week: number = this.day * 7;

  private utcTimeZoneDiff(date?: number): number {
    const time = !!date ? new Date(date) : new Date();
    const gtm = time.toString().match(/GMT[+-]\d+/i);
    if (!gtm || !gtm.length) return 0

    const timeDiff = gtm[0].split(/GMT/i).filter(x => !!x)[0];
    const hours = parseInt(timeDiff, 10) / 100;

    return hours
  }

  private getWorkingHours(key: string | number, config: DayConfiguration) {
    const day = config[key];
    return {
      startHour: day && day.startHour,
      startMinutes: day && day.startMinutes,
      endHour: day && day.endHour,
      endMinutes: day && day.endMinutes,
    }
  }

  constructor(
    private config: ConfigurationService,
    private utilities: UtilitiesService,
  ) {
    this.subscription = this.config.state$
      .subscribe(config => {
        this.time = { ...this.time, ...config, }
      })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public startWorkingDay(date?: number): number {
    const time = !!date ? new Date(date) : new Date();

    if (this.isSpecialDate(time.getTime())) {
      const date = this.utilities.timeTemplate(time.getTime());
      const day = this.getWorkingHours(date, this.time.specialDatesConfig);
      const { specialDatesDefaultStartHour, specialDatesDefaultStartMinutes } = this.time;

      time.setUTCHours(this.utilities.numberFallback(day.startHour, [specialDatesDefaultStartHour, this.time.startHour]) - this.utcTimeZoneDiff(time.getTime()));
      time.setUTCMinutes(this.utilities.numberFallback(day.startMinutes, [specialDatesDefaultStartMinutes, this.time.startMinutes]));
      time.setUTCSeconds(0);
      time.setUTCMilliseconds(0);
      return time.getTime();
    }

    if (this.isWeekend(time.getTime())) {
      const date = time.getUTCDay();
      const day = this.getWorkingHours(date, this.time.weekendDaysConfig);
      const { weekendDayDefaultStartHour, weekendDayDefaultStartMinutes } = this.time;

      time.setUTCHours(this.utilities.numberFallback(day.startHour, [weekendDayDefaultStartHour, this.time.startHour]) - this.utcTimeZoneDiff(time.getTime()));
      time.setUTCMinutes(this.utilities.numberFallback(day.startMinutes, [weekendDayDefaultStartMinutes, this.time.startMinutes]));
      time.setUTCSeconds(0);
      time.setUTCMilliseconds(0);
      return time.getTime();
    }

    time.setUTCHours(this.time.startHour - this.utcTimeZoneDiff(time.getTime()));
    time.setUTCMinutes(this.time.startMinutes);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.getTime();
  }
  public endWorkingDay(date?: number): number {
    const time = !!date ? new Date(date) : new Date();

    if (this.isSpecialDate(time.getTime())) {
      const date = this.utilities.timeTemplate(time.getTime());
      const day = this.getWorkingHours(date, this.time.specialDatesConfig);
      const { specialDatesDefaultEndHour, specialDatesDefaultEndMinutes } = this.time;

      time.setUTCHours(this.utilities.numberFallback(day.endHour, [specialDatesDefaultEndHour, this.time.endHour]) - this.utcTimeZoneDiff(time.getTime()));
      time.setUTCMinutes(this.utilities.numberFallback(day.endMinutes, [specialDatesDefaultEndMinutes, this.time.endMinutes]));
      time.setUTCSeconds(0);
      time.setUTCMilliseconds(0);
      return time.getTime();
    }

    if (this.isWeekend(time.getTime())) {
      const date = time.getUTCDay();
      const day = this.getWorkingHours(date, this.time.weekendDaysConfig);
      const { weekendDayDefaultEndHour, weekendDayDefaultEndMinutes } = this.time;

      time.setUTCHours(this.utilities.numberFallback(day.endHour, [weekendDayDefaultEndHour, this.time.endHour]) - this.utcTimeZoneDiff(time.getTime()));
      time.setUTCMinutes(this.utilities.numberFallback(day.endMinutes, [weekendDayDefaultEndMinutes, this.time.endMinutes]));
      time.setUTCSeconds(0);
      time.setUTCMilliseconds(0);
      return time.getTime();
    }

    time.setUTCHours(this.time.endHour - this.utcTimeZoneDiff(time.getTime()));
    time.setUTCMinutes(this.time.endMinutes);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.getTime();
  }
  public workingHours(date?: number): number {
    const startHour = new Date(this.startWorkingDay(date)).getHours();
    const endHour = new Date(this.endWorkingDay(date)).getHours();
    const hours = endHour - startHour;
    return hours <= 0 ? 24 : hours;
  }
  public isOffDay(date?: number): boolean {
    const time = !!date ? new Date(date) : new Date();
    const timeTemplate = this.utilities.timeTemplate(time.getTime());
    const isTempDayOff = Boolean(this.time.tempOffDates.find(date => date === timeTemplate));

    return this.time.offDays.includes(time.getUTCDay()) || isTempDayOff;
  }
  public isAfterWorkingTime(date?: number): boolean {
    const time = !!date ? new Date(date) : new Date();

    return (this.endWorkingDay(time.getTime()) < time.getTime());
  }
  public isWorkingTime(date?: number): boolean {
    const time = !!date ? new Date(date) : new Date();
    if (this.isOffDay(time.getTime())) return false
    return (((this.startWorkingDay(time.getTime()) < time.getTime()) && (this.endWorkingDay(time.getTime()) > time.getTime())));
  }
  public isWeekend(date?: number): boolean {
    const time = !!date ? new Date(date) : new Date();
    return this.time.weekendDays.includes(time.getDay());
  }
  public isSpecialDate(date?: number): boolean {
    const time = !!date ? new Date(date) : new Date();
    const timeTemplate = this.utilities.timeTemplate(time.getTime());

    return Boolean(this.time.specialDates.find(date => date === timeTemplate));
  }
  public addMinutes(count: number, origin?: number): number {
    if (!!origin) return origin + (count * this.minute);

    return this.startWorkingDay() + (count * this.minute);
  }
  public addHours(count: number, origin?: number): number {
    if (!!origin) return origin + (count * this.hour);

    return this.startWorkingDay() + (count * this.hour);
  }
  public addDays(count: number, origin?: number): number {
    if (!!origin) return origin + (count * this.day);

    const date = new Date();
    date.setDate(date.getUTCDate() + count);

    return this.startWorkingDay(date.getTime());
  }
  public addWeeks(count: number, origin?: number): number {
    if (!!origin) return origin + (count * this.week);

    return this.startWorkingDay() + (count * this.week);
  }
  public getWeek(date: number): number {
    const time = !!date ? new Date(date) : new Date();

    // ISO week date weeks start on Monday, so correct the day number
    const nDay = (time.getDay() + 6) % 7;

    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    time.setDate(time.getDate() - nDay + 3);

    // Store the millisecond value of the target date
    const n1stThursday = time.valueOf();

    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    time.setMonth(0, 1);

    // Not a Thursday? Correct the date to the next Thursday
    if (time.getDay() !== 4) {
      time.setMonth(0, 1 + ((4 - time.getDay()) + 7) % 7);
    }

    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - time.valueOf()) / 604800000);
  }
  public getDay(date: number): string {
    const day = !!date ? new Date(date) : new Date();
    day.setUTCHours(day.getUTCHours() + this.utcTimeZoneDiff(day.getTime()));

    return `${day.getUTCMonth()}-${day.getUTCDate()}-${day.getUTCFullYear()}`;
  }
  public getMonth(date: number): number {
    const month = !!date ? new Date(date) : new Date();
    month.setUTCHours(month.getUTCHours() + this.utcTimeZoneDiff(month.getTime()));

    return month.getMonth();
  }
}
