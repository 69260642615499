import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { RouterModule } from '@angular/router';
import { ContextService } from '@context';
import { CookieService } from '@core/services/cookie.service';
import { NotificationService } from '@core/services/notification.service';
import { ClientOrderService } from '@core/services/order.service';
import { RepositoryService } from '@core/services/repository.service';
import { SeoService } from '@core/services/seo.service';
import { TimeService } from '@core/services/time.service';
import { AdvertiseService } from '@core/services/top-banner.service';
import { UtilitiesService } from '@core/services/utilities.service';
import { SharedModule } from '@core/shared.module';
import { environment } from '@env';
import { ModalsModule } from '@modals/modals.module';
import { FeaturedCategoryComponent } from '@pages/home/featured-category/featured-category.component';
import { HomeComponent } from '@pages/home/home.component';
import { ShellModule } from '@pages/shell/shell.module';
import { ScreenService } from '@root/screen.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FeaturedCategoryComponent
  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    RouterModule,
    SharedModule,
    ShellModule,
    ModalsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    SeoService,
    TimeService,
    ContextService,
    ScreenService,
    RepositoryService,
    CookieService,
    NotificationService,
    ClientOrderService,
    UtilitiesService,
    AdvertiseService,
  ],
  exports: [SharedModule, AngularFirestoreModule],

})
export class AppModule { }
