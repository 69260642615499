<h2 class="root-heading | mat-display-2 underlined-text">
  <a [routerLink]="category.url" class="heading">
    <app-icon [src]="category.image"></app-icon>
    <span>{{ category.name }}</span>
  </a>
</h2>

<app-carousel
  *ngIf="{ products: products$ | async } as vm"
  class="product-offers"
  [indicatorsBadge]="false"
  auto-hide
>
  <app-product-card
    *ngFor="let item of vm.products; index as i; trackBy: trackByFn"
    [data]="item"
    [lazy]="lazyImage(i)"
  ></app-product-card>

  <app-product-card-placeholder [routerLink]="category.url"
    >Виж всички {{ category.name }}
  </app-product-card-placeholder>
</app-carousel>
