import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContextService } from '@context';

type CarouselTypes = 'page' | 'element';
const DEFAULT_TYPE: CarouselTypes = 'page';
const DEFAULT_MAX_ITEMS_COUNT = 4;
const DEFAULT_AUTO_SCROLL_TIME = 8000;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() indicators: boolean = true;
  @Input() indicatorsBadge: boolean = true;
  @Input() buttons: boolean = true;
  @Input() maxItems: number = DEFAULT_MAX_ITEMS_COUNT;
  @Input() type: CarouselTypes = DEFAULT_TYPE;
  @Input() autoScrollType: CarouselTypes = DEFAULT_TYPE;
  @Input() defaultPage: number = 0;
  @Output() public onChange = new EventEmitter<number>();
  @Output() public onClick = new EventEmitter<number>();
  @ViewChild('indicatorsRef') indicatorsRef!: ElementRef;
  @ViewChild('indicatorsBadgeRef') indicatorsBadgeRef!: ElementRef;
  private scrollableRef: HTMLElement | null = null;
  private scrollableListWidth: number = 0;
  private scrollableListRight: number = 0;
  private scrollableListGap: number = 0;
  private scrollableListLeft: number = 0;
  private scrollableListLastIndex: number = 0;
  private autoScrollTimeInterval: number = DEFAULT_AUTO_SCROLL_TIME;
  private autoScrollIntervalId: any;
  private throttleTimeoutId: any;
  private isBot: boolean = true;
  public page: number = this.defaultPage;
  public pages: number = 1;

  private get availablePages(): number {
    if (!this.scrollableRef) return 0
    let currentPage = 0;
    let currentLength = 0;
    for (const el of Array.from(this.scrollableRef.children)) {
      const currentChildWidth = el.getBoundingClientRect().width + this.scrollableListGap;
      currentLength = currentLength + currentChildWidth;
      if (this.scrollableListWidth < currentLength - this.scrollableListGap) {
        currentLength = currentChildWidth;
        currentPage = ++currentPage
      }
      (el as HTMLElement).dataset['page'] = `${currentPage}`;
    }
    return currentPage + 1
  }

  public get indicatorsArray(): Array<number> {
    const length = this.maxItems < this.pages ? this.maxItems : this.pages;
    return [...new Array(length)]
      .map((i, idx) => {
        if (this.page < this.maxItems - 1) return idx
        if (this.page === this.pages - 1) return idx + (this.page - (this.maxItems - 1))
        return (this.page + (idx - (this.maxItems - 2)))
      })
  }

  constructor(
    private elRef: ElementRef,
    private context: ContextService
  ) { }

  ngOnInit(): void {
    this.elRef.nativeElement.setAttribute('aria-roledescription', 'carousel');
  }

  ngAfterContentInit(): void {
    this.isBot = this.context.state.isBot;
    if (this.context.state.isServer || this.isBot) return

    this.scrollableRef = this.elRef.nativeElement.querySelector('.root-list');
    if (!this.scrollableRef) return
    this.elRef.nativeElement.dataset.scrollLeft = "none";
    this.elRef.nativeElement.dataset.scrollRight = "none";
    const computedStyle = window.getComputedStyle(this.scrollableRef);
    this.scrollableListLeft = this.scrollableRef.getBoundingClientRect().left;
    this.scrollableListRight = this.scrollableRef.getBoundingClientRect().right;
    this.scrollableListGap = Number(computedStyle.columnGap.replace('px', ''));
    this.scrollableListWidth = this.scrollableRef.getBoundingClientRect().width;
    this.scrollableListLastIndex = this.scrollableRef.children.length - 1;
    this.pages = this.availablePages;

    const observerFull = new IntersectionObserver(this.handleObserverFull, { root: null, threshold: 0.95, rootMargin: '80px' });
    Array.from(this.scrollableRef.children).forEach((c: any, i: number) => {
      c.setAttribute('aria-label', `${i + 1} of ${this.scrollableRef?.children.length}`);
      c.setAttribute('aria-roledescription', 'item');

      observerFull.observe(c)
    });

    if (Number(this.elRef.nativeElement?.dataset['autoPlay'])) {
      const observerOut = new IntersectionObserver(this.handleObserverOut, { root: null, threshold: 0.1, rootMargin: '40px' });
      observerOut.observe(this.scrollableRef)
    }

    this.handleAutoHide();
    this.scrollableRef?.addEventListener('scroll', this.handleAutoHide, true);
    this.scrollableRef?.setAttribute('role', 'group');
    this.scrollableRef?.setAttribute('aria-label', 'item scroller');
    this.scrollableRef?.setAttribute('aria-live', 'Polite');

    if (this.defaultPage) {
      this.handleScroll(this.defaultPage);
    }
  }

  ngOnDestroy(): void {
    this.scrollableRef?.removeEventListener('scroll', this.handleAutoHide);
  }

  private normalizePage(page: number | null = null, isLeft: boolean | null = null): number {
    let currentPage = page;

    if (!currentPage && currentPage !== 0) currentPage = isLeft ? this.page - 1 : this.page + 1
    if (currentPage <= 0) currentPage = 0
    if (currentPage >= this.pages) currentPage = this.pages
    return currentPage
  }

  private resetAutoScroll(): void {
    if (!this.scrollableRef || !Number(this.scrollableRef?.dataset['autoPlay'])) return

    clearInterval(this.autoScrollIntervalId);
    this.handleAutoScroll(this.autoScrollTimeInterval);
  }

  private geScrollLengthByElement = (page: number, isLeft: boolean | null): number => {
    if (!this.scrollableRef) return 0
    const parentChildren = Array.from(this.scrollableRef.children);
    const visibleChildren = parentChildren.filter((el: any) => el.dataset.view === 'true');
    const alpha = isLeft ? -1 : 1;
    const index = isLeft ?
      parentChildren.indexOf(visibleChildren[0])
      :
      parentChildren.indexOf(visibleChildren[visibleChildren.length - 1]);
    const nextSibling = isLeft ? this.scrollableRef.children[index].previousElementSibling : this.scrollableRef.children[index].nextElementSibling;
    if (nextSibling) {
      const increment = (nextSibling.getBoundingClientRect().width + this.scrollableListGap) * alpha;
      return this.scrollableRef.scrollLeft + increment;
    }
    return this.scrollableRef.scrollLeft
  }

  private getScrollLengthByPage = (page: number, isLeft: boolean | null): number => {
    if (!this.scrollableRef || page === 0) return 0
    const children = Array.from(this.scrollableRef.children).filter((el: any) => Number(el.dataset.page) < page);
    const scrollLeftLength = children.reduce((acc, el) => el.getBoundingClientRect().width + acc, 0);
    return scrollLeftLength + children.length * this.scrollableListGap
  }

  public handleScroll = (page: number | null = null, isLeft: boolean | null = null, type?: CarouselTypes): void => {
    if (!this.scrollableRef) return
    const SCROLL_TYPES = {
      'page': this.getScrollLengthByPage,
      'element': this.geScrollLengthByElement,
    }
    const currentPage = this.normalizePage(page, isLeft);
    this.scrollableRef.scroll({
      left: SCROLL_TYPES[type || this.type](currentPage, isLeft),
      behavior: 'smooth'
    });
    this.resetAutoScroll();
  }

  private handleObserverOut = (entries: any): void => {
    entries.forEach((el: any) => {
      if (!Number(this.scrollableRef?.dataset['autoPlay'])) return

      if (el.isIntersecting && !this.autoScrollIntervalId) {
        this.handleAutoScroll(Number(this.scrollableRef?.dataset['autoPlay']));
      }
      if (!el.isIntersecting && !!this.autoScrollIntervalId) {
        clearInterval(this.autoScrollIntervalId);
        this.autoScrollIntervalId = null;
      }
    })
  }

  private handleObserverFull = (entries: any): void => {
    entries.forEach((el: any) => {
      if (el.isIntersecting) {
        if (this.page !== Number(el.target.dataset.page)) {
          this.resetAutoScroll();
        }
        el.target.dataset.view = 'true'
        this.page = Number(el.target.dataset.page);
        this.onChange.emit(this.page);
        this.changeDetection();
      } else {
        el.target.dataset.view = 'false'
      }
    })
  }

  private changeDetection = (): void => {
    if (!this.indicatorsRef || !this.indicatorsRef.nativeElement.children.length) return
    Array.from(this.indicatorsRef?.nativeElement.children)
      .forEach((indicatorRef: any, index: number) => {
        if (!indicatorRef) return
        const indicator = this.indicatorsArray[index];
        const isSmall = (index === 0 || index === (this.maxItems - 1)) && this.pages > (this.maxItems - 1) && indicator !== 0 && indicator !== this.pages - 1
        indicatorRef.dataset.active = (this.page === indicator).toString();
        indicatorRef.dataset.page = indicator;
        indicatorRef.dataset.small = isSmall.toString();
        indicatorRef.addEventListener('click', () => this.handleScroll(indicator))
      });

    if (!this.indicatorsBadgeRef) return
    this.indicatorsBadgeRef.nativeElement.dataset.page = this.page + 1;
    this.indicatorsBadgeRef.nativeElement.dataset.pages = this.pages;
  }

  private handleAutoHide = (): void => {
    clearTimeout(this.throttleTimeoutId)
    this.throttleTimeoutId = setTimeout(() => {
      if (!this.elRef || !this.scrollableRef) return
      const currentRight = this.scrollableRef.children[this.scrollableListLastIndex].getBoundingClientRect().right - 16;
      const currentLeft = this.scrollableRef.children[0].getBoundingClientRect().left + 16;
      if (Math.floor(currentRight) > Math.floor(this.scrollableListRight)) {
        this.elRef.nativeElement.dataset.scrollRight = "block";
      } else {
        this.elRef.nativeElement.dataset.scrollRight = "none";
      }
      if (Math.floor(currentLeft) < Math.floor(this.scrollableListLeft)) {
        this.elRef.nativeElement.dataset.scrollLeft = "block";
      } else {
        this.elRef.nativeElement.dataset.scrollLeft = "none";
      }
    }, 25)
  }

  private handleAutoScroll(interval: number): void {
    if (!!interval) this.autoScrollTimeInterval = interval;
    this.autoScrollIntervalId = setInterval(() => {
      if (!this.scrollableRef) return
      const currentIndex = (this.page + 1) % this.pages;
      const isLeft = currentIndex < this.page;
      this.page = currentIndex;
      this.handleScroll(currentIndex, isLeft, this.autoScrollType);
    }, this.autoScrollTimeInterval);
  }
}
