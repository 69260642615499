import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionBodyComponent } from './accordion/accordion-body/accordion-body.component';
import { AccordionHeadComponent } from './accordion/accordion-head/accordion-head.component';
import { AccordionComponent } from './accordion/accordion.component';
import { BottomBannerComponent } from './bottom-banner/bottom-banner.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DetailsComponent } from './details/details.component';
import { DialogComponent } from './dialog/dialog.component';
import { DividerComponent } from './divider/divider.component';
import { IconComponent } from './icon/icon.component';
import { ProductCardPlaceholderComponent } from './product-card-placeholder/product-card-placeholder.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { UspCardComponent } from './usp-card/usp-card.component';

const components: any = [
  TopBannerComponent,
  ProductCardComponent,
  DividerComponent,
  ProductCardPlaceholderComponent,
  IconComponent,
  BottomBannerComponent,
  UspCardComponent,
  ProductCardComponent,
  AccordionComponent,
  AccordionHeadComponent,
  AccordionBodyComponent,
  SnackBarComponent,
  DetailsComponent,
  DialogComponent,
  CarouselComponent
];
@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [...components, CommonModule],
})
export class ComponentsModule { }

